<template>
  <div :class="classList" class="resize-pulldown" ref="boxSelect" :id="`div-resize-${idBox}`" :style="`max-width:${maxWidth}; position:relative`">
    <div class="pl-16-px box" @click="toggleDropdown()" v-click-outside="handleClickOutSite"
         :class="{'active': selectionShow}">
      <div class="box-title" v-html="label" />
      <div class="pull-down-adjust pulldown" :style="'width: ' + width">
        <div class="selection-box-bridge" ref="box">
          <div class="selection-box">
            <div v-if="showName(value)" class="input-box pull-down-text-color">
              {{ showName(value) }}
              <span class="helper-txt" v-if="suffixInOption && showSuffixInOption(value)">{{ suffixInOption }}</span>
            </div>
            <div v-else class="input-box pull-down-text-color">{{ getPlaceHolderText }}</div>
            <div class="input-icon">
              <img class="img" src="@/assets/images/pulldown/pulldown-default.svg" />
              <img class="none-image" src="@/assets/images/pulldown/pulldown-default-hover.svg" />
              <img class="active-image" src="@/assets/images/pulldown/pull-down-default-active.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="selectionShow"
      class="selection-pulldown"
      :style="'width: ' + (boxWidth + 17) + 'px; border-radius: 8px; left: '+leftPosition+'px;'"
      id="style-scroll"
      :class="items.length > 4 ? '' : 'item-scroll-none'"
    >
      <!-- <div
        v-for="(item, index) in items"
        v-bind:key="index"
        :class="[compareItem(item.value) ? 'selected-background' : '']"
        class="item pull-down-text-color"
        @click="selectedItem(item.value)"
      >
        {{ item.name || "No Name" }}
        <span class="helper-txt" v-if="suffixInOption && item.is_selected">{{ suffixInOption }}</span>
      </div> -->
      <v-virtual-scroll bench="3" :items="items" :item-height="34" :height="items.length < 5 ? 34 * items.length : 34 * 5"
        min-width="94">
        <template v-slot:default="{ item }">
          <div
            :class="[compareItem(item.value) ? 'selected-background' : '']"
            class="item pull-down-text-color"
            @click="selectedItem(item)"
          >
            {{ item.name || "No Name" }}
            <span class="helper-txt" v-if="suffixInOption && item.is_selected">{{ suffixInOption }}</span>
          </div>
        </template>
      </v-virtual-scroll>
    </div>
  </div>

</template>

<script>
import { getWidthText } from "@/utils/calcTextWidth";
export default {
  props: {
    type: {
      type: String,
      default: '',
    },
    minWidth         : {
      type   : String,
      default: "unset"
    },
    classList        : {
      type   : String,
      default: ""
    },
    label            : {
      type   : String,
      default: "&nbsp;"
    },
    items            : Array,
    width            : {
      type   : String,
      default: "150px"
    },
    model            : String,
    initialValue     : [Object, String, Number, null],
    placeHolderText  : {
      type   : String,
      default: null
    },
    isSelectFirst    : {
      type   : Boolean,
      default: false
    },
    suffixInOption   : {
      type   : String,
      default: ""
    },
    defaultIsSelected: {
      type   : Boolean,
      default: false
    },
    parentScrolled   : {
      type   : Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: '1500px',
    },
    idBox: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectionShow: false,
      value        : "",
      boxWidth     : 0,
      resizeTimer  : null,
      isSelected   : false,
      leftPosition : 0,
      selectedData: null,
      element: null,
      startWidth: 0,
      startX: 0,
    };
  },
  computed: {
    getPlaceHolderText() {
      return this.placeHolderText || this.$t("dashboard_main.placeholder_select_item_label");
    }
  },
  mounted() {
    this.isSelected = this.defaultIsSelected;
    this.boxWidth   = this.$refs.box?.clientWidth;
    this.setMinWidthByContent();
    this.onWindowResize();
    this.createElement();
    if (this.initialValue) {
      this.value = this.initialValue;
      this.selectedData = this.items.find(item => item.value === this.initialValue);
    }
  },
  methods: {
    createElement() {
      this.element = document.getElementById(`div-resize-${this.idBox}`);
      let resizer = document.createElement('div');
      resizer.className = 'resizer';
      resizer.style.width = '2px';
      resizer.style.height = '2px';
      resizer.style.position = 'absolute';
      resizer.style.top = 0;
      resizer.style.right = 0;
      resizer.style.height = '100%'
      resizer.style.cursor = 'col-resize';
      this.element.appendChild(resizer);
      resizer.addEventListener('mousedown', this.initResize, false);
    },

    initResize(e) {
      this.startX = e.clientX
      this.startWidth = parseInt(document.defaultView.getComputedStyle(this.element).width, 10);
      window.addEventListener('mousemove', this.resize, false);
      window.addEventListener('mouseup', this.stopResize, false);
    },

    resize(e) {
      e.preventDefault();
      e.stopPropagation();
      this.element.style.width = (this.startWidth + e.clientX - this.startX) + 'px';
      this.boxWidth = this.$refs.box.clientWidth;
    },

    stopResize(e) {
      window.removeEventListener('mousemove', this.resize, false);
      window.removeEventListener('mouseup', this.stopResize, false);
    },
    toggleDropdown() {
      this.selectionShow = !this.selectionShow;
      if (this.selectionShow) {
        this.leftPosition = this.$refs.box.getBoundingClientRect().left - 17;
        this.boxWidth = this.$refs.box.clientWidth;
      }
    },
    setMinWidthByContent() {
      const longestName = this.items.reduce((acc, item) => {
          if (item.name.length > acc.length) {
            return item.name;
          }
          return acc;
        }, "");

        const widthText = getWidthText(longestName, {fontSize: '11px', fontWeight: 400, letterSpacing: '0.03em' });
        this.$refs.boxSelect.style.minWidth = this.$refs.box.clientWidth + 18.2 + 'px'; // plus padding
        this.$refs.boxSelect.style.width = widthText + 60 + 'px'; // plus additional parts in pulldown 
    },
    handleClickOutSite() {
      this.selectionShow = false;
    },
    showName(value) {
      let text = ''
      if (this.type === 'period_select') {
        const returnObj = this.items.filter((obj) => {
          if (typeof value === "object") {
            if (JSON.stringify(obj.value).replaceAll("\"", "") == JSON.stringify(value).replaceAll("\"", "")) {
              return obj;
            }
          }
          if (obj.value == value) {
            return obj;
          }
        });
        text = returnObj.length > 0 ? returnObj[0].name || "No Name" : "";
        this.getWidthText(text);
        return text
      }
      if (this.initialValue) {
        text = this.items.find(item => item.value === this.initialValue)?.name;
        this.getWidthText(text);
        return text
      }
      text = this.selectedData?.name;
      this.getWidthText(text);
      return text
    },

    getWidthText(text) {
      const widthText = getWidthText(text, {fontSize: '11px', fontWeight: 400, letterSpacing: '0.03em' })

      if(widthText > 0) {
        const minWidthItem = widthText + 65;
        if(this.$refs.boxSelect && minWidthItem > this.$refs.boxSelect.style.minWidth) {
          this.$refs.boxSelect.style.minWidth = minWidthItem < 95 ? 95 : minWidthItem  + 'px';
        }
      }
    },

    selectedItem(item) {
      if (this.suffixInOption) {
        const itemOriginal = this.items.find((i) => i.value === item.value);

        if (itemOriginal && Object.prototype.hasOwnProperty.call(itemOriginal, "is_selected")) {
          this.isSelected = itemOriginal.is_selected;
        } else {
          this.isSelected = false;
        }
      }
      setTimeout(() => {
        this.value         = item.value;
      }, 1);
      this.selectedData = item;
      this.selectionShow = !this.selectionShow;
      this.$emit("updateData", item.value, this.type);
    },
    showSuffixInOption(value) {
      const result = this.items.filter((obj) => {
        if (value === obj.value) {
          return true;
        }
        return false;
      });

      if (result.length) {
        return result[0].is_selected;
      }
      return false;
    },
    compareItem(item) {
      if (typeof item === "object") {
        if (JSON.stringify(item).replaceAll("\"", "") == JSON.stringify(this.value).replaceAll("\"", "")) {
          return true;
        }
      }
      if (item == this.value) {
        return true;
      }
      return false;
    },
    closeSelection() {
      this.selectionShow = false;
    },
    onWindowResize() {
      window.addEventListener("resize", this.handleWindowResize);
    },
    offWindowResize() {
      window.removeEventListener("resize", this.handleWindowResize);
    },
    handleWindowResize(_) {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {
        this.boxWidth = this.$refs.box.clientWidth;
      }, 100);
    }
  },
  watch  : {
    initialValue() {
      if (this.initialValue) {
        this.value = this.initialValue;
      }
    },
    items() {
      if (this.isSelectFirst && this.items.length) {
        this.value = this.items[0].value;
      }
    },
    defaultIsSelected() {
      if (this.defaultIsSelected) {
        this.isSelected = true;
      }
    },
    parentScrolled: {
      handler(parentScrolled) {
        if (parentScrolled) {
          this.selectionShow = false;
        }
      }
    }
  },
  destroyed() {
    this.offWindowResize();
  },
};
</script>

<style lang="scss" scoped>
.helper-txt {
  color: #df4949;
  margin-left: 5px;
}

.pl-16-px {
  padding-left: 16px;
}

.pull-down-adjust {
  .selection-box-bridge {
    .selection-box {
      .input-box {
        width: calc(100vw - 175px);
      }
    }
  }
}

#style-scroll::-webkit-scrollbar {
  width: 16px;
}

#style-scroll::-webkit-scrollbar-thumb {
  background-color: #bfd4d9;
  border-radius: 10px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}

.selected-background {
  background: #e3eded;
  font-weight: 500 !important;
}

.item-scroll-none {
  overflow: hidden !important;
}

.placeholder-color {
  color: $monoMid;
}

.pull-down-text-color {
  color: $monoBlack;
}

.selection-pulldown {
  // display: flex;
  position: fixed;
  max-height: 170px;
  background: #f7f7f2;
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
  0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
  0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  margin-top: 2px;
  z-index: 99;
  overflow-y: hidden;
  overflow-x: hidden;

  .item {
    height: 34px;
    padding: 7px 16px 9px 16px;
    border: 1px solid rgba(42, 42, 48, 0.1);
    border-bottom: 0;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: 'Source Han Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.03em;
  }

  .item:hover {
    background: #e3eded;
  }
  .v-virtual-scroll::-webkit-scrollbar {
    width: 16px;
  }
  .v-virtual-scroll::-webkit-scrollbar-thumb {
    background-color: #bfd4d9;
    border-radius: 10px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
}

.box:not(.active):hover {
  .pull-down-adjust {
    .selection-box-bridge {
      .selection-box {
        .input-icon {
          .img {
            display: none;
          }

          .none-image {
            display: block;
          }

          .active-image {
            display: none;
          }
        }
      }
    }
  }
}

.box {
  justify-content: flex-start;
  text-align: start;
  display: block;
  margin-bottom: 0;
  .pulldown {
    display: block;

    .selection-box-bridge {
      justify-content: space-between;
      padding-bottom: 5px;

      .selection-box {
        display: flex;
        width: 100%;
        margin-bottom: 0;
        padding: 0;
        height: 15px;
        margin-right: 10px;

        .input-box {
          width: 100%;
          font-size: 11px;
          line-height: 18px;
          align-items: center;
          display: flex;
        }

        .input-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px 5px;
          margin-right: 15px;
          position: relative;
          top: -10px;
          right: -5px;

          .img {
            display: block;
          }

          .none-image {
            display: none;
          }

          .active-image {
            display: none;
          }
        }
      }
    }
  }

  .box-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 0.03em;
    color: $monoBlack;
    font-family: 'Source Han Sans';
    line-height: 18px;
    flex-grow: unset;
    width: 100%;
    padding-right: 40px;
    padding-top: 5px;
  }
  &.active {
    background: $goldLight;
    .box-title, .pull-down-text-color {
      color: $monoWhite;
    }
    .input-icon {
      transform: rotate(180deg);
      .img {
        display: none !important;
      }
      .none-image {
        display: none !important;
      }
      .active-image {
        display: block !important;
      }
    }
  }
}
.item-scroll-none {
  overflow: hidden !important;
}
</style>
